import React, { Suspense, lazy, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import vimalLogo from './assets/images/vimal-group-logo-black.png'

import Navbar from './components/Navbar'
import Footer from './components/Footer'
import HomePage from './pages/Home'

const OurGroupVimal = lazy(() => import('./pages/OurGroupVimal'))
const OurGroupDeep = lazy(() => import('./pages/OurGroupDeep'))
const OurGroupVD = lazy(() => import('./pages/OurGroupVD'))
// const AboutUs = lazy(() => import('./pages/AboutUs'))
const ContactUs = lazy(() => import('./pages/ContactUs'))
const UnderConstruction = lazy(() => import('./pages/UnderConstruction'))

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#f8f9fa'
    },
    image: {
        width: '10%'
    }
}

const LoadingFallback = () => {
    return (
        <div style={styles.container}>
            <img src={vimalLogo} alt="Loading..." style={styles.image} />
        </div>
    )
}

function App() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <Suspense fallback={LoadingFallback}>
            <div className='app'>
                <Navbar />
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/vimal-infracon' element={<OurGroupVimal />} />
                    <Route path='/deep-infra' element={<OurGroupDeep />} />
                    <Route path='/vd-zincare' element={<OurGroupVD />} />
                    <Route path='/about-us' element={<UnderConstruction />} />
                    <Route path='/contact-us' element={<ContactUs />} />
                </Routes>
                <Footer />
            </div>
        </Suspense>
    )
}

export default App
